


































































import VSelect from 'vue-select'
import { defineComponent, getCurrentInstance } from '@vue/composition-api'

import ArrowIcon from './partials/ArrowIcon.vue'
import LoadingIcon from './partials/LoadingIcon.vue'
import { SelectProps } from './Select.contracts'
import { selectProps, useSelect } from './Select.hooks'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (original)
 * @author Olga Milczek <olga.milczek@movecloser.pl> (original)
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl> (edited)
 */
export const Select = defineComponent({
  name: 'Select',
  components: { ArrowIcon, LoadingIcon, VSelect },
  props: selectProps,

  setup (props: SelectProps) {
    return useSelect(props, getCurrentInstance())
  }
})
export default Select
